<template>
  <v-container fluid class="pa-0 ma-0">
  <!-- <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'"> -->

    <v-toolbar dense flat>
      <v-btn-toggle
        v-model="toggle_multiple"
        multiple
        dense
        tile
        borderless
        color="primary accent-3"
      >
        <v-btn
          v-model="search.today"
          text
          class="px-1"
          @click="btnClick('today')"
        >
          <v-icon
            small
            :color="search.today ? 'primary' : ''"
          >mdi-calendar</v-icon>
          <span>오늘</span>
        </v-btn>
        <v-btn
          v-model="search.star"
          text
          class="px-1"
          @click="btnClick('star')"
        >
          <v-icon
            small
            :color="search.star ? 'amber darken-1' : ''"
          >mdi-star</v-icon>
          <span>중요</span>
        </v-btn>
      </v-btn-toggle>
      <!-- [정렬] 업무일/등록일 -->
      <div class="ml-2 hidden-xs-only" style="width: 150px">
        <v-select
          v-model="sort.default"
          :items="select.defaultSort"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectOrder"
        ></v-select>
      </div>
      <!-- 담당부서 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss2"
          :items="select.ss2"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss2')"
        ></v-select>
      </div>
      <!-- 관리상태 -->
      <div class="hidden-xs-only" style="width:120px">
        <v-select
          v-model="search.ss1"
          :items="select.ss1"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss1')"
        ></v-select>
      </div>
      <!-- 정보위치 -->
      <div class="hidden-xs-only" style="width:140px">
        <v-select
          v-model="search.ss3"
          :items="select.ss3"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss3')"
        ></v-select>
      </div>
      <!-- 업무유형 -->
      <div class="hidden-xs-only" style="width:140px">
        <v-select
          v-model="search.ss4"
          :items="select.ss4"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss4')"
        ></v-select>
      </div>

      <v-spacer></v-spacer>

      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
      <!-- !! 검색 아이콘 처리 영역 start -->
      <v-menu
        v-model="searchMenu"
        :close-on-content-click="false"
        :nudge-width="50"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text small class="hidden-xs-only">
            <v-icon small>mdi-magnify</v-icon>
            검색
          </v-btn>
        </template>
        <v-card
          max-width="550"
          min-width="550"
          tile
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-toolbar dark color="primary" dense flat
            class="mb-0 pb-0"
          >
            <v-toolbar-items>
              <v-btn
                dark text class="orange--text text-h6 font-weight-bold" @click="searchPopBtn">검색</v-btn>
              <v-btn
                dark text class="white--text subheading" @click="searchMenu = false">취소</v-btn>
              <v-btn text icon
                @click="initVals"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >mdi-refresh</v-icon>
                  </template>
                  <span>초기화</span>
                </v-tooltip>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-0 pt-0">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="ma-0 pa-0"
            >
              <!-- 검색어 검색 -->
              <v-col cols="12" xs="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <div style="width: 140px;">
                    <v-select
                      v-model="search.sf"
                      :items="select.sf"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-3 mr-3"
                      dense
                      style="font-size: 0.785rem !important"
                    ></v-select>
                  </div>
                  <v-text-field
                    v-model="search.sw"
                    label="검색"
                    append-icon="mdi-magnify"
                    maxlength="20"
                    clearble
                    class="mt-5 mr-3"
                    @keyup.enter="searchPopBtn"
                    @click:append="searchPopIcon"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- 검색 아이콘 처리 영역 end -->
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2">
      <div class="text-left mr-0">
        <v-chip small color="primary">
          {{ totalItems }}
        </v-chip>
        <v-btn text icon
          @click="initVals"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>
      <v-spacer></v-spacer>
      <div style="width: 60px">
        <v-select
          :items="itemsPerPageOptions"
          v-model="options.itemsPerPage"
          dense
          class="mt-6"
        ></v-select>
      </div>
    </v-toolbar>

    <v-card :elevation="0">
      <v-data-table
        :headers="headers"
        :items="datas"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-header
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        loading-text="로딩중..."
      >
        <!-- 꼼수 : id 필드에 매칭시킨 item 을 가지고 각 row 마다 작업한다. -->
        <template v-slot:[`item.id`]="{ item }">
          <v-list two-line class="pa-0 ma-0">
            <v-list-item class="float-left">
              <v-list-item-content>
                <v-list-item-title class="text-body-2">
                  <span
                    @click="viewPop(item)"
                    style="cursor:pointer"
                    class="grey--text text--darken-1"
                  >
                    <v-icon small
                      v-if="item.isStar"
                      class="mr-1 pb-1"
                      color="amber darken-1"
                    >mdi-star</v-icon>
                    <span class="text-body-2 text--primary" style="font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                    <span v-show="item._tm_" class="ml-1 text-body-2 purple--text text--lighten-1 font-weight-bold">{{ item._tm_ }}</span>
                    <!-- <v-btn
                      v-show="item._tm_"
                      x-small dark tile depressed
                      color="cyan"
                      class="mx-1 px-1"
                      style="font-size: 0.755rem !important"
                    >{{ item._tm_ }}</v-btn> -->
                    <v-chip
                      label outlined x-small class="ml-2 mr-1 px-1"
                      color="blue-grey darken-2"
                      style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                    >
                      {{ item.gubun1 }}
                    </v-chip>
                    <span class="text-body-2 primary--text" style="font-weight: 600;">
                      {{ item.str2 }}
                    </span>
                    <span class="text-body-2 text--primary">
                      {{ item.view1 ? ` ${item.view1}` : '' }}
                    </span>
                    <span class="mx-0 font-weight-bold primary--text">
                      #{{ item.gubun2 }}
                    </span>
                    <!-- !![2021.3.25] 부모정보 뷰 수정 -->
                    <v-icon small class="ml-1">mdi-link-variant</v-icon>
                    <span class="grey--text text--darken-1">
                      <template v-if="item.pType === 1">
                        {{ item.pInfo.courtName }} {{ item.pInfo.caseNum }}{{ item.pInfo.manageNum ? `/${item.pInfo.manageNum}` : '' }}
                        <span class="primary--text">{{ item.dcl }}</span>{{ item.dop }}
                      </template>
                      <template v-else-if="item.pType === 2">
                        {{ item.pInfo.manageNum }}
                        <span class="primary--text">{{ item.dcl }}</span>{{ item.dop }}
                      </template>
                      <template v-else-if="item.pType === 3">
                        {{ item.pInfo.name }}
                      </template>
                      <template v-else>
                        독립메뉴 > 업무
                      </template>
                      {{ item.mgroups ? ` - ${item.mgroups}` : '' }}
                      {{ item.teamName ? ` [${item.teamName}]` : ''}}
                    </span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text-body-2 text--primary">
                    {{ cutString(item.str1, 150) }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div class="text-center pt-3 pb-3">
        <v-pagination
          v-model="options.page"
          :length="pages"
        ></v-pagination>
      </div>
    </v-card>

    <!-- !! 소송/자문 업무 팝업 -->
    <rel-content-dialog01 ref="relContentDialog01" :article="article"></rel-content-dialog01>
    <!-- !! 인명부 업무 팝업 -->
    <rel-content-dialog02 ref="relContentDialog02" :article="article"></rel-content-dialog02>
    <!-- !! 인명부 업무 팝업 2: [2021.6.28] 고문계약자동갱신 -->
    <rel-content-dialog03 ref="relContentDialog03" :article="article"></rel-content-dialog03>
    <!-- !! 독립메뉴 업무 등록 다이얼로그 참 -->
    <upmu-add-dialog ref="upmuAddDialog"></upmu-add-dialog>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

// @: filters
import cutString from '@/filters/cutString'
import strDateFormat2 from '@/filters/strDateFormat2'
import strDateFormat3 from '@/filters/strDateFormat3'

// @: dialogs
import relContentDialog01 from '@/components/suit/relContentDialog' // 소송/자문 업무 팝업
import relContentDialog02 from '@/components/client/relContentDialog' // 인명부 업무 팝업 1
import relContentDialog03 from '@/components/client/arContentDialog' // 인명부 업무 팝업 2 - 고문계약자동갱신팝업
import upmuAddDialog from '@/components/UpmuAddDialog' // 독립메뉴 업무 팝업

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'

// @: 현재 디렉토리
// const currDir = 'work'

export default {
  components: {
    relContentDialog01,
    relContentDialog02,
    relContentDialog03,
    upmuAddDialog
  },

  data: () => ({
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'left',
        sortable: false
      }
    ],
    // 구분: 게시판용 변수들
    datas: [],
    totalItems: 0,
    itemsPerPageOptions: [ 15, 30, 50, 100 ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {},
      sort: [ 'date1' ], // 주의: date1 - 정보일순 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'DESC' ],
      offset: 0,
      limit: 1
    },
    // 구분: 초기값 설정이 필요한 변수들
    options: { // v-data-table 에 의해 자동으로 기본값이 들어있는 객체.
      itemsPerPage: 15, // 초기값을 지정했다. 기본값은 15라 매뉴얼에 나옴
      page: 1
    },
    toggle_multiple: [], // v-btn-toggle 의 초깃값이 없는 상태
    loading: false,
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '', // 검색어 검색 text box input 값
      today: false, // 오늘 검색 버튼 클릭값 - true/false
      star: false, // 중요 검색 버튼 클릭값 - true/false
      ss1: '', // [관리상태] 셀렉트 검색 선택값
      ss2: '', // [담당부서] 셀렉트 검색 선택값
      ss3: '', // [정보위치] 셀렉트 검색 선택값
      ss4: '' // [업무유형] 셀렉트 검색 선택값
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 1 // 일자순 정렬 매칭 - 정렬의 기본값은 select.sort.value 로 결정
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 셀렉트
        { text: '업무자', value: 1 },
        { text: '내용', value: 2 },
        { text: '장소', value: 3 },
        { text: '등록자', value: 4 }
      ],
      ss1: [ // 관리상태 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '관리상태', value: '' }
      ],
      ss2: [ // 담당부서 셀렉트  - 나머지는 카테고리에서 불러온다
        { text: '담당부서', value: '' }
      ],
      ss3: [ // 정보위치
        { text: '정보위치', value: '' },
        { text: '소송', value: 1 },
        { text: '자문', value: 2 },
        { text: '인명부', value: 3 },
        { text: '독립메뉴', value: 100 }
      ],
      ss4: [ // 업무유형 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '업무유형', value: '' }
      ],
      defaultSort: [ // 기본 정렬 셀렉트 - order 가 매칭되어있다.
        { text: '정보일순', value: 1, field: 'date1', order: 'DESC' },
        // { text: '등록일순', value: 2, field: 'createdAt', order: 'DESC' }
        { text: '업데이트순', value: 2, field: 'updatedAt', order: 'DESC' }
      ]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/검색 - sort/select
    // 이름: 요소이름 - sort.defalut
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      { type: 'sort', name: 'default', select: 'defaultSort', showSelect: '', isEver: true, loading: true },
      { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss2', select: 'ss2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss3', select: 'ss3', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss4', select: 'ss4', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'sw', select: '', showSelect: '', isEver: false, loading: false }
      // { type: 'search', name: 'sw', select: '', showSelect: 'sf', isEver: false, loading: false }
    ],
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 기타 변수들
    timeout: null, // delay() 에서 사용하는 변수 - 초기화 불필요
    mgTitle: { // 관리그룹의 제목. 카테고리에서 패칭해서 채운다. 관리그룹이 늘어나면 더 늘려야 한다.
      1: '',
      2: ''
    },
    // 구분: 검색 팝업을 위한 변수
    searchMenu: false,
    // 구분: 소송/자문, 인명부 업무 팝업을 위한 변수
    article: {},
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    }
  }),

  computed: {
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    pages () { // 페이지네이션 객체에 쓰인다
      if (this.options.itemsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 담당부서 select - 나의 팀목록 불러오기
    this.getTeams().then(teams => {
      if (teams && teams.length > 0) {
        this.select.ss2.push(...teams)
      }
    })

    // 카테고리: 업무유형 = 9
    this.setCates('9').then(({ cates }) => {
      if (cates && cates.length > 0) {
        this.select.ss4.push(...cates.map(c => ({ text: c.gubun1, value: c.gubun1 })))
      }
    })

    // !! [2021.3.9 추가] 관리상태 카테고리 참고하여 ss1 셀렉트 만들기
    this.setCates('22').then(({ cates }) => {
      if (cates && cates.length > 0) {
        this.select.ss1.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
      }
    })

    // 사용안함: [2021.2.26] 관리상태 추가로 에러가 나서 - list() 로 옮김
    // 로딩시 등장해야할 검색어 칩을 찾아서 띄운다. 전돨되는 값은 배열이다.
    // this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
  },

  // 자동감지하고 리스트 재패칭 - 즉각적인 반영이 안될시 delay() 를 써야한다!
  watch: {
    '$route' (to, from) {
      // 내부등록시 리프레시 로직
      if (to.params.id) {
        const paramIds = to.params.id.split('-')
        if (paramIds.length > 1 && paramIds[1] === 'R') {
          // !! 등록
          // 등록시엔 '-R' 이 붙어온다. 이를 통해 리스트 초기화를 시킨다.
          this.initVals().then(() => {
            this.$router.push(`/work`) // 리스트로 다시 보낸다.
          })
        } else if (paramIds[0] === 'LR') {
          // 주의: 삭제
          // 리스트 리프레시로 파라미터가 날아온 경우 > 삭제의 경우
          this.options.page = 1 // 1페이지로 이동
          this.delay(50) // 리프레시 후
          this.$router.push(`/work`) // 리스트로 다시 보낸다.
        }
      }
    },
    // 중요: mounted 에서 리스트를 부를 필요없이 이것만으로 초기 로딩이 된다!
    options: {
      handler () {
        this.list()
      },
      deep: true
    },
    'options.itemsPerPage': { // 페이징 갯수 변경 자동감지
      handler () {
        this.options.page = 1 // 1페이지로 이동
        this.params.offset = 0 // 옵셋을 초기화 하지 않으면 에러
        this.delay(50)
      }
    }
  },

  methods: {
    cutString,
    strDateFormat2,
    strDateFormat3,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 팀정보 에러시 처리하는 함수 - overlay 같은게 여기엔 없다
    async popTeamError (msg) {
      try {
        // 팝업 에러창
        const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('접근권한', msg, { color: 'warning', width: 400 })) {
          await this.list() // list refresh
        } else {
          await this.list()
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // ---- 여기 까지
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {}
        this.params.sort = [ 'date1' ]
        this.params.order = [ 'DESC' ]
        this.params.offset = 0
        this.params.limit = 1

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값
        this.search.today = false // 오늘 검색 버튼 클릭값 - true/false
        this.search.star = false // 중요 검색 버튼 클릭값 - true/false
        this.search.ss1 = '' // 관리상태 셀렉트 선택값
        this.search.ss2 = '' // 담당부서 셀렉트 선택값
        this.search.ss3 = '' // 정보위치 셀렉트 선택값
        this.search.ss4 = '' // 업무유형 셀렉트 선택값

        // 구분: 정렬 기본값 매칭(갯수만큼)
        this.sort.default = 1

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.items = []
        this.totalItems = 0
        this.options.itemsPerPage = 15
        this.options.page = 1
        this.toggle_multiple = []
        this.loading = false

        // 구분: 검색어 칩 배열도 초기화
        this.searchKeywords = []
        // 그리고 초기 검색어 칩을 다시 띄움
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

        resolve(true)
      })
    },
    // !! 리스트 패칭
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit

        // !! 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (let key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params)

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get(`lawork/lwc/workList`, { params: this.params })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 참고: 리스트 데이터 반영
        this.datas = data.list
        if (data.list.length > 0) {
          data.list.forEach(item => {
            // !!
            item.view1 = '' // 장소 + 시간
            item.view2 = '' // 부모의 정보
            item._tm_ = '' // 시간
            let v1Ary = []

            // 장소, 시간 패칭
            if (item.str3) { // 장소
              v1Ary.push(item.str3)
            }
            if (!item.noTime) { // 시간없음이 아닌 경우만 시간패칭
              item._tm_ = item.date1.substr(11, 5)
              v1Ary.push(item._tm_)
            }
            // let ms = item.date1.substr(11, 5) // 업무 시간:분
            // if (ms !== '00:00') {
            //   v1Ary.push(ms)
            // }
            // !![2021.3.24] 장소시간이 없으면 ()만 나옴 보기싫어서 수정함
            item.view1 = (v1Ary.length) ? `(${v1Ary.join(' ')})` : ''

            // 중요: [2021.3.22] 부모정보 pInfo문자열이 있다면(소송/자문/인명부) 객체로 풀어준다.
            if (item.pInfo) {
              item.pInfo = JSON.parse(item.pInfo)

              // 소송/자문의 경우 당사자(의뢰인/상대방) 처리
              if (item.pType === 1 || item.pType === 2) {
                item.dcl = item.pInfo.clName ? `${item.pInfo.clType ? `[${item.pInfo.clType}]` : ''}${item.pInfo.clName}` : ''
                item.op = item.pInfo.opName ? `${item.pInfo.opType ? `[${item.pInfo.opType}]` : ''}${item.pInfo.opName}` : ''
                item.dop = (item.dcl && item.op) ? `/${item.op}` : item.op
              }

              // 그룹을 따로 패칭한다.
              item.mgroups = Array.of(item.pInfo.mgroup1, item.pInfo.mgroup2).filter(m => m).join('|')
            }
          })
        }
        this.loading = false

        // 중요: [2021.3.9] 관리상태 추가로 에러발생 - 여기로 해도 되네?
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 1) 검색어 검색 처리
        await this.searchWord()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.9.30]팝업검색시 돋보기 아이콘을 클릭할때 검색처리 - 검색어가 없으면 아무것도 안함
    async searchPopIcon () {
      try {
        // 사용안함:검색어가 있는 경우만 searchPopBtn() 를 실행
        // if (this.search.sw) {
        //   await this.searchPopBtn()
        // }
        await this.searchPopBtn()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - true/false 검색 버튼 처리 함수
    async btnClick (elem) {
      try {
        this.search[elem] = !this.search[elem]
        this.params.where[elem] = this.search[elem]

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 셀렉트 검색 처리 메소드
    // 주의: 소송분야 상세(ss3) 때문에 약간 변형됨
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        // !! 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        // !! 검색어 칩 처리 - type = search  && name = sw
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'sw')
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정렬처리 메소드 모음 ----
    // this.select.{가변이름}Sort 로 여러개의 소트를 처리할 수 있다.
    // 여러개의 소트가 있어도 처리 가능하다
    // this.params.sort 를 초기화 하고 모든 소트를 새로 만든다.
    async selectOrder () {
      try {
        // 초기화
        this.params.sort = []
        this.params.order = []

        for (let key in this.sort) {
          const selectSortValue = this.sort[key]
          const field = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].field
          const order = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].order

          this.params.sort.push(field)
          this.params.order.push(order)

          // 검색어 칩 - type = sort(정렬)  && name = elem
          const kw = this.useSearchKeywords.find(k => k.type === 'sort' && k.name === key)
          await this.setSearchKeywords(kw)
        }

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver }

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        // select 가 있으면 select 에서 보여줄 text 를 가져온다
        if (kw.select) {
          const sel = this.select[kw.select].find(k => k.value === currVal)
          skw.text = (sel.value) ? sel.text : ''
        } else {
          // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
          // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
          if (kw.showSelect) {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
            } else {
              skw.text = ''
            }
          } else {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `"${currVal}"`
            } else {
              skw.text = ''
            }
          }
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 업무 팝업 열기
    async viewPop (item) {
      try {
        // console.log(item)
        // 중요: pId 와 pType 은 무척 중요
        const { pId, pType } = item

        if (pType === 1 || pType === 2) {
          // 부모가 소송 or 자문인 경우
          // !! this.article 을 넘기기 위해 패칭
          const { data } = await this.$axios.get(`lawork/case/getCase/${pId}/${pType}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.article) {
            this.article = data.article

            // 소송이면 caseNum 자문이면 manageNum 을 담는 변수
            const targetNum = pType === 1 ? this.article.caseNum : this.article.manageNum

            // 중요: 2021.2.1 들어갈 수 없는 팀이면 에러를 내고 리스트로 백한다.
            if (this.article.teamError) {
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${targetNum}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
              //
            } else {
              // !! 업무팝업 열기
              const result = await this.$refs.relContentDialog01.open('업무', { width: 650 }, 1, item)
              // 중요: 2021.2.8 수정 - 리턴값이 객체로 변경됨.
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${targetNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${targetNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // !! 정상적으로 수정/삭제된 경우 리스트 리프레시
                  await this.list()
                }
              }
            }
          } else {
            // !! 이미 삭제된 데이터인 경우 처리
            await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
          }
        } else if (pType === 3) {
          // 부모가 인명부
          // !! this.article 을 넘기기 위해 패칭
          const { data } = await this.$axios.get(`lawork/client/getClient/${pId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.article) {
            this.article = data.article

            if (this.article.teamError) {
              // 들어갈 수 없는 팀에 속한 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
              //
            } else {
              // !! 인명부 업무팝업 열기
              // 중요: 2021.2.8 수정 - 리턴값이 객체로 변경됨.
              // !![2021.6.28] 고문계약자동갱신인 경우 다른 팝업으로 열도록 추가
              let result = null
              if (item.subtype2 === 0) {
                result = await this.$refs.relContentDialog02.open('업무', { width: 650 }, 1, item)
              } else {
                result = await this.$refs.relContentDialog03.open('업무', { width: 650 }, 1, item)
              }

              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.name}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // !! 정상적으로 수정/삭제된 경우 리스트 리프레시
                  await this.list()
                }
              }
            }
          } else {
            // !! 이미 삭제된 데이터인 경우 처리
            await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
          }
        } else {
          // 독립메뉴 업무인 경우
          // 중요: 독립메뉴는 부모가 없으므로 별다른 처리가 필요없다

          // !! 업무 수정팝업 열기
          // const result = await this.$refs.upmuAddDialog.open('업무', { width: 650 }, 1, item)
          // if (result === 'removed') { // !! 삭제된 경우 리스트 리프레시
          //   await this.list() // list refresh
          // } else { // !! 수정된 경우 리스트 리프레시
          //   await this.list() // 리스트 리프레시
          // }

          // !![2021.4.5] 삭제던 수정이던 리스트 리프레시 하므로 조건문 불필요
          await this.$refs.upmuAddDialog.open('업무', { width: 650 }, 1, item)
          await this.list() // list refresh
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 유틸리티 함수 모음 -------
    // 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자신이 속한 팀을 가져온다
    // 기본팀은 필수, (관리자팀,물품구매팀,회의실관리팀은 제외)
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = '사건관리 - 업무 목록'

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfListStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '유형', style: 'tableHeader', alignment: 'center' },
          { text: '업무자', style: 'tableHeader', alignment: 'center' },
          { text: '장소', style: 'tableHeader', alignment: 'center' },
          { text: '시간', style: 'tableHeader', alignment: 'center' },
          { text: '상태', style: 'tableHeader', alignment: 'center' },
          { text: '정보위치', style: 'tableHeader', alignment: 'center' },
          { text: '담당부서', style: 'tableHeader', alignment: 'center' }
        ])

        this.datas.forEach(d => {
          // 정보위치
          let pInfo = '독립업무'
          if (d.pType === 1) {
            pInfo = `${d.pInfo.caseNum}${d.pInfo.manageNum ? `/${d.pInfo.manageNum}` : ''}`
          } else if (d.pType === 2) {
            pInfo = d.pInfo.manageNum
          } else if (d.pType === 3) {
            pInfo = d.pInfo.name
          }
          tBody.push(
            [
              { rowSpan: 2, alignment: 'center', margin: [0, 10, 0, 0], text: `${d.isStar ? '★ ' : ''}${this.$moment(d.date1).format('YY.MM.DD')}` },
              d.gubun1,
              d.wname,
              d.str3,
              `${this.$moment(d.date1).format('HH:mm')}`,
              d.gubun2,
              pInfo,
              d.teamName
            ],
            ['', { colSpan: 7, text: d.str1, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, 60, 80, '*', 'auto', 'auto', '*', 'auto'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
